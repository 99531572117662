import React, { useState } from 'react'

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
} from 'reactstrap'
import classnames from 'classnames'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import SliderDebates from '../components/SliderDebates'
import DebatesBox from '../components/DebatesBox'

import '../scss/page-talks-and-courses.scss'

import debatesBoxImg from '../images/blm-protests.png'

const TalksPage = () => {
  const [activeTab, setActiveTab] = useState('1')

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <Layout>
      <Seo title="Talks & Courses | Talk of the Town" />

      <Hero text="Talks & Courses" />
      <SliderDebates
        sectionTitle="Cursos Ao vivo"
        buttonUrl=""
        buttonText=""
      >
        <Row className="justify-center">
          <DebatesBox
            key="week2"
            widthClass="col-12 col-lg-4"
            background={debatesBoxImg}
            dateHour="21/06/20 - 16h30"
            title="Yuval Harari"
            description="Lorem ipsum dolor sit amet dolor consectetur adisplicing."
            buttonUrl="/course/01"
            buttonText="Ver detalhes"
          />
          <DebatesBox
            key="week1"
            widthClass="col-12 col-lg-4"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="Black lives matter"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/course/01"
            buttonText="Ver detalhes"
          />
          <DebatesBox
            key="week3"
            widthClass="col-12 col-lg-4"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="Black lives matter"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/course/01"
            buttonText="Ver detalhes"
          />
        </Row>
      </SliderDebates>
      <section className="courseTabs">
        <Container>
          <Row>
            <Col sm="12">
              <h3>Cursos On Demand</h3>
            </Col>
            <Col sm="12">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === '1',
                    })}
                    onClick={() => {
                      toggle('1')
                    }}
                  >
                    Todos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === '2',
                    })}
                    onClick={() => {
                      toggle('2')
                    }}
                  >
                    Artes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === '3',
                    })}
                    onClick={() => {
                      toggle('3')
                    }}
                  >
                    Ciência
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === '4',
                    })}
                    onClick={() => {
                      toggle('4')
                    }}
                  >
                    Política
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col sm="12">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                    <DebatesBox
                      widthClass="col-12 col-lg-3 fade-in"
                      background={require('../images/blm-protests.png')}
                      dateHour="20/06/20 - 16h30"
                      title="Lorem ipsum dolor sit"
                      buttonUrl="/course/21"
                      buttonText="Ver detalhes"
                    />
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default TalksPage
